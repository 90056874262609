import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
// import '../assets/css/result.css';
// import '../assets/css/vod-main01.css';
import '../assets/css/vod-main02.css';
import '../assets/css/vod-detail02.css';
import { appendLoading, removeLoading, scrollToBottom, applyLogOut, checkUserId } from '../services/common-util';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const [movieIds, setMovieIds] = useState([]);
    const [ontologies, setOntologies] = useState([]);
    const [genres, setGenres] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [actors, setActors] = useState([]);
    const [recommendedMovies, setRecommendedMovies] = useState([]);
    const [metaList, setMetaList] = useState([]);
    const [curationList, setCuraitonList] = useState([]);
    const [selectedOntologies, setSelectedOntologies] = useState([]);
    const [moviesInfo, setMoviesInfo] = useState({});
    const [searchKeyword, setSearchKeyword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMetaData = async () => {
            try {
                const response = await axios.get('https://movie.o2o.kr/api/v1/random-metadata?lang=en');
                console.log("response : ", response);
                const data = response.data.metaList;
                console.log("metaList : ", data);
                setMetaList(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchMetaData();
    }, []);

    useEffect(() => {
        const fetchCurationData = async () => {
            try {
                const response = await axios.get('https://movie.o2o.kr/api/v2/serendipity/curation?nx=127&ny=38&pool_index=6&pool_type=n&lang=en');
                console.log("serendipity response : ", response);
                const data = response.data.curation_list;
                console.log("curation_list : ", data);
                setCuraitonList(data);

                fetchMovieDetails(data);  // 데이터를 전체 curation 리스트로 전달
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCurationData();
    }, []);

    const fetchMovieDetails = async (curationList) => {
        const newMoviesInfo = {}; // 중복 방지를 위한 새로운 객체
        for (const curation of curationList) {
            newMoviesInfo[curation.curation_title] = []; // 각 큐레이션에 대해 새로운 배열 생성
            for (const movie of curation.movie_list) {
                console.log('Fetching details for movie_id: ', movie.movie_id);
                let movie_id_each = movie.movie_id;
                try {
                    const response = await axios.get(`https://movie.o2o.kr/api/v1/movie/info?pool_index=6&pool_type=n&movie_id=${movie.movie_id}&lang=en`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                    });
                    if (response.data) {
                        console.log('Response data: ', response.data);
                        const { movie_img, movie_id, movie_name } = response.data;
                        newMoviesInfo[curation.curation_title].push({ movie_img, movie_id, movie_name, movie_id_each });
                    } else {
                        console.error('No data found for movie_id: ', movie.movie_id);
                    }
                } catch (error) {
                    if (error.response) {
                        console.error(`Error fetching movie details for ID ${movie.movie_id}:`, error.response.data);
                    } else {
                        console.error(`Error fetching movie details for ID ${movie.movie_id}:`, error.message);
                    }
                }
            }
        }
        setMoviesInfo(newMoviesInfo); // 중복된 데이터를 제거한 후 상태 업데이트
    };

    const handleMetaClick = (value) => {
        setSelectedOntologies(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const flaskRequestForRecommendMovie = async () => {
        appendLoading();

        let poolIndex = 6;
        let poolType = 'n';

        const url = `https://movie.o2o.kr/api/v1/otlg/recommendation?movie_ids=${movieIds}&ontologies=${selectedOntologies.join(',')}&genres=${genres}&directors=${directors}&actors=${actors}&pool_index=${poolIndex}&pool_type=${poolType}&lang=en`;
        console.log("url:", url);

        try {
            const response = await axios.get(url, { timeout: 15000 });
            removeLoading();
            return response.data;
        } catch (error) {
            removeLoading();
            console.log(error);
            return null;
        }
    };

    const handleRecommendButtonClick = async () => {
        const recommendedData = await flaskRequestForRecommendMovie();
        if (recommendedData) {
            setRecommendedMovies(recommendedData);
            const poolIndex = 6;
            const poolType = 'n';
            const url = `/otlg/recommendation?ontologies=${selectedOntologies.join(',')}&pool_index=${poolIndex}&pool_type=${poolType}&lang=en`;
            navigate(url);
        } else {
            Swal.fire('Error', 'Failed to fetch recommended movies', 'error');
        }
    };

    const handleRecommendItemClick = (event) => {
        const movieId = event.currentTarget.querySelector('img').id;
        const poolIndex = 6;
        const poolType = 'n';
        const url = `https://movie.o2o.kr/movie/info?movie_id=${movieId}&pool_index=${poolIndex}&pool_type=${poolType}`;
        window.location.href = url;
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        const url = `/movie/search?keyword=${searchKeyword}`;
        window.location.href = url;
    };

    return (
        <div>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
                <div className="header-tab-container">
                    <div className="tab-position-guider">
                        <div className="tab home-tab">
                            <a href="/">Home</a>
                            <div className="under-bar"></div>
                        </div>
                    </div>
                </div>
                <form className="search-form" onSubmit={handleSearchSubmit}>
                    <input 
                        type="text" 
                        placeholder="Search..." 
                        value={searchKeyword} 
                        onChange={(e) => setSearchKeyword(e.target.value)} 
                    />
                    <button type="submit">Search</button>
                </form>
            </header>
            <section className="main">
                <div className="meta-container">
                    <div className='meta-detail-content-container'>
                        <div className="meta-container-title">
                            O2O's metadata generator based content recommendation system
                        </div>

                        {/* <div className="meta-container-title">
                            O2O 메타데이터 생성 기반 콘텐츠 추천 시스템
                        </div> */}
                        <div className='ontology-checkbox-container'>
                            {metaList.map(value => (
                                <label
                                    className={`ontology-checkbox ${selectedOntologies.includes(value) ? 'selected' : ''}`}
                                    key={value}
                                    onClick={() => handleMetaClick(value)}
                                >
                                    <input
                                        type="checkbox"
                                        name="ontology"
                                        value={value}
                                        checked={selectedOntologies.includes(value)}
                                        onChange={() => handleMetaClick(value)}
                                    />
                                    <div className="detail-meta-value-container">{value}</div>
                                </label>
                            ))}
                        </div>
                        <div className="button-container">
                            <button
                                type="button"
                                className="detail-submit-button recommend-button"
                                onClick={handleRecommendButtonClick}
                            >
                                Recommended Movies
                                {/* 영화 추천 */}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="meta-form">
                    {curationList.map((curation, index) => (
                        <div className="recommend-item-container" key={index}>
                            <div className="attribute-name-container">
                                <strong>{curation.curation_title}</strong>
                            </div>
                            <div className="recommend-attribute-container" id={curation.curation_title}>
                                {moviesInfo[curation.curation_title] && moviesInfo[curation.curation_title].map(movieInfo => (
                                    <div className="recommend-content-container" key={movieInfo.movie_id} onClick={handleRecommendItemClick}>
                                        <img src={movieInfo.movie_img} alt={movieInfo.movie_name} id={movieInfo.movie_id_each} />
                                        <div className="item-footer-container">
                                            <div className="movie-name">{movieInfo.movie_name}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Home;
