export const fetchWithTimeout = (url, options) => {
    const { timeout = 8000 } = options;

    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('timeout')), timeout)
        )
    ]);
};

// export const appendLoading = () => {
//     // 로딩 표시 추가 로직
//     const loadingElement = document.createElement('div');
//     loadingElement.id = 'loading';
//     loadingElement.innerText = 'Loading...';
//     document.body.appendChild(loadingElement);
// };

// export const removeLoading = () => {
//     // 로딩 표시 제거 로직
//     const loadingElement = document.getElementById('loading');
//     if (loadingElement) {
//         document.body.removeChild(loadingElement);
//     }
// };

export const appendLoading = () => {
    // 로딩 표시 추가 로직
    const loadingElement = document.createElement('div');
    loadingElement.id = 'loading';

    // Create an img element for the loading image
    const loadingImage = document.createElement('img');
    loadingImage.src = '/path/to/your/loading-image.gif'; // Replace with the actual path to your loading image
    loadingImage.src = process.env.PUBLIC_URL + 'imgs/Loading_2.gif' ;
    loadingImage.alt = 'Loading...';

    // Append the image to the loading element
    loadingElement.appendChild(loadingImage);

    // Append the loading element to the body
    document.body.appendChild(loadingElement);
};

export const removeLoading = () => {
    const loadingElement = document.getElementById('loading');
    if (loadingElement) {
        document.body.removeChild(loadingElement);
    }
};


export const scrollToBottom = (duration) => {
    const element = document.documentElement;
    const start = element.scrollTop;
    const change = element.scrollHeight - element.scrollTop;
    const startDate = +new Date();

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else {
            element.scrollTop = element.scrollHeight;
        }
    };
    animateScroll();
};

export const applyLogOut = () => {
    // 로그아웃 로직
    console.log('User logged out');
};

export const checkUserId = () => {
    // 사용자 ID 확인 로직
    console.log('Check user ID');
};
