import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../assets/css/vod-main02.css';
import '../assets/css/vod-detail02.css';
import { appendLoading, removeLoading, scrollToBottom, applyLogOut, checkUserId } from '../services/common-util';

const Home = () => {
    const [movieIds, setMovieIds] = useState([]);
    const [ontologies, setOntologies] = useState([]);
    const [genres, setGenres] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [actors, setActors] = useState([]);
    const [recommendedMovies, setRecommendedMovies] = useState([]);
    const [metaList, setMetaList] = useState([]); // State to store the metaList
    const [selectedOntologies, setSelectedOntologies] = useState([]); // State to store selected ontologies

    useEffect(() => {
        let currentUrl = window.location.href;
        console.log("Current URL:", currentUrl); // Log the current URL for debugging
        
        let baseUrl = "https://movie.o2o.kr/";
        let apiUrl = currentUrl.replace(baseUrl, 'https://movie.o2o.kr/api/v1/');
        console.log("apiUrl:", apiUrl);

        axios.get(apiUrl)
            .then(response => {
                console.log("response : ", response);
                const data = response.data.recommend_result;
                console.log("recommend_result : ", data);
                setMetaList(data); // Set the fetched metaList to state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleMovieClick = (movie_id, pool_index, pool_type) => {
        const url = `/movie/info?movie_id=${movie_id}&pool_index=${pool_index}&pool_type=${pool_type}`;
        window.location.href = url;
    };

    return (
        <div>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
                <div className="header-tab-container">
                    <div className="tab-position-guider">
                        <div className="tab home-tab">
                            <a href="/">Home</a>
                            <div className="under-bar"></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main">
                <form className='main-form'>
                    {metaList.length > 0 ? (
                        metaList.map(item => (
                            Object.keys(item).map(attribute => (
                                <div className="recommend-item-container" key={attribute}>
                                    <div className="attribute-name-container"><strong>{attribute}</strong></div>
                                    <div className="recommend-attribute-container">
                                        {item[attribute].map((movie, pool_index) => (
                                            <div
                                                className="recommend-content-container"
                                                key={movie.movie_id}
                                                onClick={() => handleMovieClick(movie.movie_id, '6', 'n')}
                                            >
                                                <img src={movie.image} alt={movie.name} id={movie.movie_id} />
                                                <div className="item-footer-container">
                                                    <div className="movie-name">{movie.name}</div>
                                                    {/* <div>{`${attribute} : ${movie.otlg_val[0]}`}</div> */}
                                                    {movie.otlg.map((otlgItem, index) => (
                                                        <div key={index}>{`${otlgItem} : ${movie.otlg_val[index]}`}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Home;
