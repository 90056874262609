import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OtlgRecommendation from './components/OtlgRecommendation';
import MovieInfo from './components/MovieInfo';
import MovieSearch from './components/MovieSearch';
import Home from './components/Home';
import NotFound from './components/NotFound';

const App = () => { 
    return (
        <Router>
            <div>
                {/* <Navbar /> */}
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/otlg/recommendation" element={<OtlgRecommendation />} />
                    <Route exact path="/movie/info" element={<MovieInfo />} />
                    <Route exact path="/movie/search" element={<MovieSearch />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
