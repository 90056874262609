import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../assets/css/vod-main02.css';
import '../assets/css/vod-detail02.css';
import { appendLoading, removeLoading, scrollToBottom, applyLogOut, checkUserId } from '../services/common-util';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const [metaData, setMetaData] = useState(null); // State to store the metaData
    const [similarData, setSimilarData] = useState([]); // State to store the similar movies data
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        let currentUrl = window.location.href;
        console.log("Current URL:", currentUrl); // Log the current URL for debugging

        let baseUrl = "https://movie.o2o.kr/movie/info?";
        let apiUrl = currentUrl.replace(baseUrl, 'https://movie.o2o.kr/api/v1/movie/info?lang=en&');
        console.log("apiUrl:", apiUrl);

        axios.get(apiUrl)
            .then(response => {
                console.log("response : ", response);
                const data = response.data;
                console.log("data : ", data);
                setMetaData(data); // Set the fetched metaData to state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        let currentUrl = window.location.href;
        console.log("Current URL:", currentUrl); // Log the current URL for debugging

        let baseUrl = "https://movie.o2o.kr/movie/info?";
        let apiUrl = currentUrl.replace(baseUrl, 'https://movie.o2o.kr/api/v1/movie/similar_movie?lang=en&');
        console.log("similar_movie apiUrl:", apiUrl);

        axios.get(apiUrl)
            .then(response => {
                console.log("similar_movie response : ", response);
                const data = response.data.movies; // Access the movies array directly
                console.log("similar_movie data : ", data);
                setSimilarData(data); // Set the fetched similarData to state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleClick = (id) => {
        const newUrl = `https://movie.o2o.kr/movie/info?movie_id=${id}&pool_index=6&pool_type=n&lang=en`;
        window.location.href = newUrl;
    };

    const renderOntologyRows = (ontologies, id) => {
        const filteredOntologies = ontologies.filter(ontology => ontology[Object.keys(ontology)[0]][0] === id);

        if (filteredOntologies.length === 0) {
            return (
                <tr>
                    <td colSpan="3">None</td>
                </tr>
            );
        }

        return filteredOntologies.map((ontology, index) => {
            const key = Object.keys(ontology)[0];
            const value = ontology[key];
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{key}</td>
                    <td>{value[1]}</td>
                </tr>
            );
        });
    };

    const renderOntologies = (ontologies) => {
        return ontologies.map((ontology, index) => {
            const key = Object.keys(ontology)[0];
            return (
                <label key={index} className="ontology-checkbox">
                    <input type="checkbox" name="ontology" value={key} />
                    <div className="detail-ontology-value-container">{key}</div>
                </label>
            );
        });
    };

    const renderGenres = (genres) => {
        return genres.map((genre, index) => (
            <label key={index} className="genre-checkbox">
                <input type="checkbox" name="genre" value={genre} />
                <div className="detail-genre-value-container">{genre}</div>
            </label>
        ));
    };

    const renderDirectors = (directors) => {
        return directors.map((director, index) => (
            <label key={index} className="director-checkbox">
                <input type="checkbox" name="director" value={director} />
                <div className="detail-director-value-container">{director}</div>
            </label>
        ));
    };

    const renderActors = (actors) => {
        return actors.map((actor, index) => (
            <label key={index} className="actor-checkbox">
                <input type="checkbox" name="actor" value={actor} />
                <div className="detail-actor-value-container">{actor}</div>
            </label>
        ));
    };

    const renderSimilarMovies = (similarMovies) => {
        if (!Array.isArray(similarMovies) || similarMovies.length === 0) {
            return <div>No similar movies found.</div>;
        }

        return similarMovies.map((movie, index) => (
            <div key={index} className="recommend-content-container" onClick={() => handleClick(movie.movie_id)}>
                <img src={movie.movie_img} id={movie.movie_id} alt={movie.movie_name} />
                <div className="item-footer-container">
                    {/* <div className="movie-name">{movie.movie_name} <br />유사도 : {movie.similarity}</div> */}
                    <div className="movie-name">{movie.movie_name} <br />Similarity : {movie.similarity}</div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
                <div className="header-tab-container">
                    <div className="tab-position-guider">
                        <div className="tab home-tab">
                            <a href="/">Home</a>
                            <div className="under-bar"></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main">
                {metaData && (
                    <div className='movie-detail-container'>
                        <div className='detail-thumbnail-container'>
                            <div className='detail-img-container'>
                                <img src={metaData.movie_img} alt={metaData.movie_name} />
                            </div>
                            <div className='movie-detail-content-container'>
                                <div className='movie-detail-title'>
                                    {metaData.movie_name}
                                </div>
                                <div className='detail-attribute-container'>
                                    <div className='detail-ontology-container'>
                                        <div className='detail-attribute-title'>Attributes</div>
                                        {/* <div className='detail-attribute-title'>속성</div> */}
                                        <div className='ontology-checkbox-container'>
                                            {renderOntologies(metaData.ontology)}
                                        </div>
                                    </div>

                                    <div className='detail-genre-container'>
                                        <div className='detail-attribute-title'>Genres</div>
                                        {/* <div className='detail-attribute-title'>장르</div> */}
                                        <div className='genre-checkbox-container'>
                                            {renderGenres(metaData.genre)}
                                        </div>
                                    </div>

                                    <div className='detail-director-container'>
                                        <div className='detail-attribute-title'>Director</div>
                                        {/* <div className='detail-attribute-title'>감독</div> */}
                                        <div className='director-checkbox-container'>
                                            {renderDirectors(metaData.director)}
                                        </div>
                                    </div>

                                    <div className='detail-actor-container'>
                                        <div className='detail-attribute-title'>Actors</div>
                                        {/* <div className='detail-attribute-title'>배우</div> */}
                                        <div className='actor-checkbox-container'>
                                            {renderActors(metaData.actor)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='attribute-description'>
                            <div>Attribute</div>
                            {/* <div>속성</div> */}
                            <div>Here are the percentage values of {metaData.movie_name} measured by O2O's metadata generator.</div>
                            <div>Only attributes with values above 1% are displayed</div>
                            {/* <div>O2O의 메타데이터 생성기에 의해 측정된 {metaData.movie_name} 메타데이터 속성 값 입니다.</div> */}
                            {/* <div>1% 이상의 값만 표시됩니다.</div> */}

                            <div className='table-container'>
                                <table className='detail-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Attribute</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id='0'>
                                        {renderOntologyRows(metaData.ontology, '0')}
                                    </tbody>
                                </table>
                                <div className='detail-table-container'>
                                    <table className='detail-table'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>History</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody id='1'>
                                            {renderOntologyRows(metaData.ontology, '1')}
                                        </tbody>
                                    </table>
                                </div>
                                <table className='detail-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Location</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id='2'>
                                        {renderOntologyRows(metaData.ontology, '2')}
                                    </tbody>
                                </table>
                                <table className='detail-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Modifier</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id='3'>
                                        {renderOntologyRows(metaData.ontology, '3')}
                                    </tbody>
                                </table>
                                <table className='detail-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Genre</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id='4'>
                                        {renderOntologyRows(metaData.ontology, '4')}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='recommend-container'>
                            <div className='recommend-item-container'>
                                {/* <div className="attribute-name-container"><strong>유사 영화</strong></div> */}
                                <div className="attribute-name-container"><strong>Similar Movies</strong></div>
                                <div className='recommend-attribute-container'>
                                    {renderSimilarMovies(similarData)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;