import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../assets/css/vod-main02.css';
import '../assets/css/vod-detail02.css';
import { useNavigate } from 'react-router-dom';

const HomeSearch = () => {
    const [metaData, setMetaData] = useState(null); // State to store the metaData
    const [similarData, setSimilarData] = useState([]); // State to store the similar movies data
    const [detailedMovies, setDetailedMovies] = useState([]);
    const [metaList, setMetaList] = useState([]);
    const [selectedOntologies, setSelectedOntologies] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    const navigate = useNavigate(); // Initialize useNavigate hook

    // URLSearchParams 객체를 사용하여 URL의 쿼리 파라미터를 파싱합니다.
    let urlParams = new URLSearchParams(window.location.search);

    // 'keyword' 파라미터의 값을 가져옵니다.
    let keyword = urlParams.get('keyword');

    useEffect(() => {
        const fetchMetaData = async () => {
            try {
                let currentUrl = window.location.href;
                console.log("Current URL:", currentUrl); // Log the current URL for debugging

                let urlParams = new URLSearchParams(window.location.search);
                let keyword = urlParams.get('keyword');
                console.log("Extracted Keyword:", keyword); // Log the extracted keyword for debugging

                let apiUrl = 'https://movie.o2o.kr/api/v1/otlg/search?pool_index=6&pool_type=n&lang=en' + '&keyword=' + keyword;
                console.log("otlg search apiUrl:", apiUrl);

                const response = await axios.get(apiUrl);
                console.log("otlg search response : ", response);

                const data = response.data.search_result_otlg;
                console.log("otlg search data : ", data);

                setMetaData(data); // Set the fetched metaData to state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchMetaData();
    }, []);

    useEffect(() => {
        const fetchMovies = async () => {
            try {
                let currentUrl = window.location.href;
                console.log("Current URL:", currentUrl); // Log the current URL for debugging

                let apiUrl = 'https://movie.o2o.kr/api/v1/movie/search?pool_index=6&pool_type=n&lang=en' + '&keyword=' + keyword;
                console.log("movie search apiUrl:", apiUrl);

                const response = await axios.get(apiUrl);
                console.log("movie search response : ", response);
                const data = response.data.search_result_movie; // Access the movies array directly
                console.log("movie search data : ", data);
                setSimilarData(data); // Set the fetched similarData to state

                // Fetch details for each movie
                const detailedDataPromises = data.map(movie =>
                    axios.get(`https://movie.o2o.kr/api/v1/movie/info?pool_index=6&pool_type=n&movie_id=${movie.movie_id}&lang=en`)
                );

                const detailedDataResponses = await Promise.all(detailedDataPromises);
                console.log("detailedDataResponses : ", detailedDataResponses);
                const detailedData = detailedDataResponses.map(response => response.data);
                console.log("detailedData : ", detailedData);
                setDetailedMovies(detailedData); // Set the fetched detailed movie data to state

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchMovies();
    }, [keyword]);

    const handleMetaClick = (value) => {
        setSelectedOntologies(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        const url = `/movie/search?keyword=${searchKeyword}`;
        window.location.href = url;
    };

    return (
        <div>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
                <div className="header-tab-container">
                    <div className="tab-position-guider">
                        <div className="tab home-tab">
                            <a href="/">Home</a>
                            <div className="under-bar"></div>
                        </div>
                    </div>
                </div>
                <form className="search-form" onSubmit={handleSearchSubmit}>
                    <input 
                        type="text" 
                        placeholder="Search..." 
                        value={searchKeyword} 
                        onChange={(e) => setSearchKeyword(e.target.value)} 
                    />
                    <button type="submit">Search</button>
                </form>
            </header>
            <div className="main">
                <div className="meta-container">
                    <div className='meta-detail-content-container'>
                        <div className="meta-container-title">
                            O2O's metadata generator based content recommendation system
                        </div>
                        
                        {/* <div className="meta-container-title">
                            O2O 메타데이터 생성 기반 콘텐츠 추천 시스템
                        </div> */}
                        <div className='ontology-checkbox-container'>
                            {metaList.map(value => (
                                <label
                                    className={`ontology-checkbox ${selectedOntologies.includes(value) ? 'selected' : ''}`}
                                    key={value}
                                    onClick={() => handleMetaClick(value)}
                                >
                                    <input
                                        type="checkbox"
                                        name="ontology"
                                        value={value}
                                        checked={selectedOntologies.includes(value)}
                                        onChange={() => handleMetaClick(value)}
                                    />
                                    <div className="detail-meta-value-container">{value}</div>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="meta-form recommend-item-container">
                    {metaData && Array.isArray(metaData) ? (
                        <div className="meta-data-container">
                            <div className='attribute-name-container'>Meta Data</div>
                            {/* <div className='attribute-name-container'>메타 데이터</div> */}
                            <div className='ontology-checkbox-container'>
                                {metaData.map((item, index) => (
                                    <label className="ontology-checkbox" key={index}>
                                        <input type="checkbox" name="ontology" value={item} />
                                        <div className="detail-meta-value-container">{item}</div>
                                    </label>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p>Loading meta data...</p>
                    )}
                </div>

                <div className="similar-data-container">
                    <div className='attribute-name-container'>Similar Movies</div>
                    {/* <div className='attribute-name-container'>유사 영화</div> */}
                    {detailedMovies && Array.isArray(detailedMovies) && detailedMovies.length > 0 ? (
                        detailedMovies.map((movie, index) => (
                            <div className="detail-thumbnail-container" key={index}>
                                <div className="detail-img-container">
                                    <img src={movie.movie_img} alt={movie.movie_name} />
                                </div>
                                <div className="movie-detail-content-container">
                                    <div className="movie-detail-title">{movie.movie_name}</div>
                                    <div className="detail-attribute-container">
                                        <div className="detail-ontology-container">
                                            <div className="detail-attribute-title">Attributes</div>
                                            <div className="ontology-checkbox-container">
                                                {movie.ontology && movie.ontology.map((ont, idx) => {
                                                    const key = Object.keys(ont)[0];
                                                    return (
                                                        <label className="ontology-checkbox" key={idx}>
                                                            <input type="checkbox" name="ontology" value={key} />
                                                            <div className="detail-ontology-value-container">{key}</div>
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="detail-genre-container">
                                            <div className="detail-attribute-title">Genres</div>
                                            <div className="genre-checkbox-container">
                                                {movie.genre && movie.genre.map((genre, idx) => (
                                                    <label className="genre-checkbox" key={idx}>
                                                        <input type="checkbox" name="genre" value={genre} />
                                                        <div className="detail-genre-value-container">{genre}</div>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="detail-director-container">
                                            <div className="detail-attribute-title">Director</div>
                                            <div className="director-checkbox-container">
                                                {movie.director && movie.director.map((director, idx) => (
                                                    <label className="director-checkbox" key={idx}>
                                                        <input type="checkbox" name="director" value={director} />
                                                        <div className="detail-director-value-container">{director}</div>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="detail-actor-container">
                                            <div className="detail-attribute-title">Actors</div>
                                            <div className="actor-checkbox-container">
                                                {movie.actor && movie.actor.map((actor, idx) => (
                                                    <label className="actor-checkbox" key={idx}>
                                                        <input type="checkbox" name="actor" value={actor} />
                                                        <div className="detail-actor-value-container">{actor}</div>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Loading similar movies...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomeSearch;
